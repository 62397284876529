import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const PostCard = styled.div`
    display: block;
    text-decoration: none;
    padding-top: 24px;
    border-top: 1px solid var(--gray-lighter);

    .PostCard__image {
        margin-bottom: var(--space-unit);
        .cover-image {
            padding-bottom: 56%;
        }
    }

    .PostCard__category {
        margin: var(--space-sm) 0;
    }

    .PostCard__title {
        color: var(--gray-dark);
        margin: var(--space-sm) 0;
        text-decoration-line: underline;
        text-decoration-color: transparent;
        transition: all 0.3s ease;
    }
    .gatsby-image-wrapper {
        transition: transform 20s linear;
    }
    .cover-image {
        border-radius: 10px;
        overflow: hidden;
    }
    &:hover {
        .gatsby-image-wrapper {
            transform: scale(1.4) rotate(-2deg);
        }
    }
    a {
        text-decoration: none;
    }
`
const baseUrl = `https://www.${process.env.GATSBY_SUBDOMAIN || ''}ehealthinsurance.com/resources`

export default ({ post, isPost }) => (
    <PostCard className="post-card-wrapper">
        <Link
            to={
                isPost && post.slug === "5-common-myths-about-supplemental-health-insurance"
                    ? `${baseUrl}/dental-insurance/comprehensive-guide-supplemental-dental-insurance`
                    : isPost
                        ? `${baseUrl}/${post.categories[0].slug}/${post.slug}`
                        : `${baseUrl}/${post.slug}`
            }
        >
            <div className="PostCard__image">
                <div className="cover-image cover-image--ratio">
                    {post.featured_media && post.featured_media.localFile && post.featured_media.localFile.childImageSharp && (
                        <Img
                            sizes={
                                post.featured_media.localFile.childImageSharp
                                    .sizes
                            }
                        />
                    )}
                </div>
            </div>
            <div className="PostCard__content">
                <h4
                    className="PostCard__title"
                    dangerouslySetInnerHTML={{ __html: post.title }}
                ></h4>
            </div>
        </Link>
    </PostCard>
)
